<template>
  <div>
    <a-card>
      <div class="header">关于我们</div>
      <a-form
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="页面标题">
              <a-input
                allow-clear
                placeholder="请输入页面标题"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机型号">
              <a-select
                v-model:value="value"
                label-in-value
                style="width: 180px"
                :options="options"
                @change="handleChange"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="页面背景">
              <a-input
                allow-clear
                placeholder="请输入页面背景颜色"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          label=" "
          :colon="false"
          :labelCol="{ span: 2, offset: 0 }"
          name="virtualViews"
        >
          <a-button type="primary" @click="save"> 提交 </a-button>
        </a-form-item>
      </a-form>
      <!-- <div class="width">
        <div>宽度</div>
        <div>
          <a-select
            v-model:value="value"
            label-in-value
            style="width: 120px"
            :options="options"
            @change="handleChange"
          >
          </a-select>
        </div>
      </div> -->
      <div class="tinymce">
        <tinymce-editor
          ref="tinymce"
          v-model:value="content"
          :init="{ width: width, height: height }"
        />
      </div>
    </a-card>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
export default {
  name: 'aboutUsEdit',
  components: {
    TinymceEditor
  },
  data() {
    return {
      options: [
        {
          value: 0,
          width: 320,
          height: 568,
          label: 'iPhone5'
        },
        {
          value: 1,
          width: 375,
          height: 667,
          label: 'iPhone6/7/8'
        },
        {
          value: 2,
          width: 414,
          height: 736,
          label: 'iPhone6/7/8 Plus'
        },
        {
          value: 3,
          width: 375,
          height: 812,
          label: 'iPhoneX'
        },
        {
          value: 4,
          width: 414,
          height: 896,
          label: 'iPhoneXR'
        },
        {
          value: 5,
          width: 414,
          height: 896,
          label: 'iPhoneXR Max'
        },
        {
          value: 6,
          width: 375,
          height: 812,
          label: 'iPhone12/13 mini'
        },
        {
          value: 7,
          width: 390,
          height: 844,
          label: 'iPhone12/13 (pro)'
        },
        {
          value: 8,
          width: 428,
          height: 926,
          label: 'iPhone12/13 Pro Max'
        },
        {
          value: 9,
          width: 360,
          height: 640,
          label: 'Nexus 5'
        },
        {
          value: 10,
          width: 411,
          height: 731,
          label: 'Nexus 5X'
        },
        {
          value: 11,
          width: 412,
          height: 732,
          label: 'Nexus 6'
        },
        {
          value: 12,
          width: 480,
          height: 800,
          label: 'Windows'
        },
        {
          value: 13,
          width: 375,
          height: 667,
          label: 'Mac 13-inch and below'
        },
        {
          value: 14,
          width: 375,
          height: 736,
          label: 'Mac 15-inch'
        },
        {
          value: 15,
          width: 414,
          height: 896,
          label: 'Mac 21-inch and above'
        },
        {
          value: 16,
          width: 768,
          height: 1024,
          label: 'iPad'
        },
        {
          value: 17,
          width: 834,
          height: 1112,
          label: 'iPad Pro 10.5-inch'
        },
        {
          value: 18,
          width: 1024,
          height: 1366,
          label: 'iPad Pro 12.9-inch'
        }
      ],
      // 选择默认值
      width: 320,
      height: 568
    }
  },
  methods: {
    handleChange(e) {
      const data = this.options[e.value]
      this.$refs.tinymce.changeWidth('editor1', data.width, data.height)
    }
  }
}
</script>

<style lang='less'>
.header {
  padding: 10px;
  size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.width {
  display: flex;
  flex-direction: row;
}
.tinymce{
  display: flex;
  justify-content: center;
}
</style>
